import React from "react";

interface TabsProps {
  tabs: { name: "gallery" | "summary"; label: string }[]; // Typisierung für Tabs
  activeTab: "gallery" | "summary"; // Der aktuelle aktive Tab muss auch "gallery" oder "summary" sein
  onTabChange: (tab: "gallery" | "summary") => void; // Callback, der den aktiven Tab ändert
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div className="flex justify-center my-4">
      {tabs.map((tab) => (
        <button
          key={tab.name}
          onClick={() => onTabChange(tab.name)} // Korrekte Typisierung für den Tab-Wert
          className={`px-4 py-2 font-semibold ${
            activeTab === tab.name
              ? "border-b-2 border-white text-white"
              : "text-gray-500"
          }`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
