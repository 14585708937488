import React, { useState } from "react";
import ProjectPosts from "./components/projectPosts";
import { getPostList } from "./components/postList";

const Home: React.FC = () => {
  // Alle Posts aus der Funktion holen
  const allPosts = getPostList();
  
  // State zum Speichern des aktuell ausgewählten Tags
  const [selectedTag, setSelectedTag] = useState<string>("");

  // Funktion, um den Tag zu setzen, der gefiltert wird
  const filterPosts = (tag: string) => {
    setSelectedTag(tag);
  };

  // Gefilterte Posts basierend auf dem ausgewählten Tag
  const filteredPosts = selectedTag
    ? allPosts.filter((post) => post.tags.includes(selectedTag))
    : allPosts; // Wenn kein Tag ausgewählt ist, zeige alle Posts an

  return (
    <div className="text-center">
      <div className="hidden w-full md:flex justify-end">
        <div className="my-5 fixed z-20 backdrop-blur-xl p-5 mr-6 !rounded-2xl text-sm">
          <a
            className="cursor-pointer text-white mx-5"
            onClick={() => filterPosts("")}
          >
            Show All
          </a>
          <a
            className="cursor-pointer text-white mx-5"
            onClick={() => filterPosts("Photography")}
          >
            Photography
          </a>
          <a
            className="cursor-pointer text-white mx-5"
            onClick={() => filterPosts("Videography")}
          >
            Videography
          </a>
          <a
            className="cursor-pointer text-white mx-5"
            onClick={() => filterPosts("Design")}
          >
            Design
          </a>
        </div>

    </div>
      {/* Die gefilterten Posts anzeigen */}
      <ProjectPosts posts={filteredPosts}></ProjectPosts>
      </div>
  );
};

export default Home;
