import React from "react";

interface ProjectRightProps {
  imageSrc: string; // Bildquelle
  altText: string;  // Alt-Text für das Bild
  title: string;    // Titeltext
  description: string; // Beschreibungstext
}

const ProjectRight: React.FC<ProjectRightProps> = ({
  imageSrc,
  altText,
  title,
  description
}) => {
  return (
    <div className="h-screen items-center px-10 flex md:flex-row flex-col justify-center">
      {/* Text-Container */}
      <div className="md:w-1/2 h-max-[33vh] xl:h-max-full w-full flex justify-center align-center flex-col mr-0 md:mr-5 xl:mr-10 xl:my-20 my-0 ml-20">
        <h2 className="text-white text-4xl mb-2 font-bold">
          {title}
        </h2>
        <p
          id="about_text"
          className="text-white text-justify text-sm xl:text-base font-['Arial'] test"
        >
          {description}
        </p>
      </div>

      {/* Bild-Container */}
      <div className="md:w-1/2 w-full max-h-[66vh] xl:max-h-full flex justify-center xl:ml-10 ml-0 xl:my-20 my-2 xl:mr-20 mr-1">
        <img
          className="object-contain object-center"
          src={imageSrc}
          alt={altText} // Setze den Alt-Text für Barrierefreiheit und SEO
        />
      </div>
    </div>
  );
};

export default ProjectRight;
