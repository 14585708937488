import React from "react";

interface ProjectLeftProps {
  imageSrc: string;  // Bildquelle
  altText: string;   // Alt-Text für das Bild
  title: string;     // Titeltext
  description: string; // Beschreibungstext
}

const ProjectLeft: React.FC<ProjectLeftProps> = ({ imageSrc, altText, title, description }) => {
  return (
    <div className="h-screen items-center px-10 flex md:flex-row flex-col justify-center">
      {/* Bild-Container */}
      <div className="md:w-1/2 w-full max-h-[66vh] xl:max-h-full flex justify-center xl:ml-20 ml-0 xl:my-20 my-2 xl:mr-10 mr-1">
        <img
          className="object-contain object-center"
          src={imageSrc}
          alt={altText} // Setze den Alt-Text für Barrierefreiheit und SEO
        />
      </div>

      {/* Text-Container */}
      <div className="md:w-1/2 h-max-[33vh] xl:h-max-full w-full flex justify-center align-center flex-col ml-0 md:ml-5 xl:ml-10 xl:my-20 my-0 xl:mr-20 mr-0">
        <h2 className="text-white text-4xl mb-2 font-bold">
          {title}
        </h2>
        <p
          id="about_text"
          className="text-white text-justify text-sm xl:text-base font-['Arial'] test"
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default ProjectLeft;
