import React from "react";
import Portrait from "./img/portrait.jpg";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP);

const About: React.FC = () => {
  const container = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline | null>(null);

  useGSAP(
    () => {
      
    },
    { scope: container }
  );

  return (
    <div ref={container}>
      <div className="h-screen px-10 flex md:flex-row flex-col justify-center mt-5 md:mt-0">
        <div className="md:w-1/2 w-full max-h-[66vh] xl:max-h-full flex justify-center xl:ml-20 ml-0 xl:my-20 my-2 xl:mr-10 mr-1">
          <img
            className="object-cover object-center"
            src={Portrait}
            alt="Zeigt das Portrait vom Fotografen & Videograf Mael Ozelley"
          />
        </div>
        <div className="md:w-1/2 h-max-[33vh] xl:h-max-full w-full flex justify-center align-center flex-col ml-0 md:ml-5 xl:ml-10  xl:my-20 my-0 xl:mr-20 mr-0">
        <h1 className=" text-left">About me</h1>
          <p className="text-white text-justify text-sm xl:text-base font-['Arial'] pb-10">
          Hi, mein Name ist Mael Ozelley und ich bin ein begeisterter
            Fotograf und Filmemacher. Meine Begeisterung dafür
            entwickelte sich schon früh, als ich mit meinen Eltern auf Reisen
            war und dabei Tiere, neue Kulturen und beeindruckende Landschaften
            entdeckte. Was mich dazu inspiriert hat, diese einzigartigen Momente
            festzuhalten. Was damals mit einem Fernglas und meinem Handy begann,
            setze ich heute mit meiner Sony Kamera professionell um.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
