import React from 'react';
import plakat from './img/meinungsfreiheit.png';

export const Meinungsfreiheit: React.FC = () => {
  return (
    <div className=''>
    <div className="w-full overflow-hidden">
        <img className='object-cover' src={plakat} alt="" />
    </div>
    <div className='h-screen w-full px-10 flex items-center'>
      <div className='flex flex-col text-start md:text-center items-start md:items-center'>
      <h1 className='text-2xl md:text-8xl'>"Jeder Mund sollte sprechen dürfen."</h1>
      <p className='text-white w-full md:w-1/2 text-start md:text-center text-sm md:text-xl'>
Mit diesem Projekt möchte ich darauf aufmerksam machen, wie wichtig Meinungsfreiheit ist. Leider gibt es viele Menschen, deren Stimmen unterdrückt oder nicht gehört werden. Das Klebeband auf dem Mund symbolisiert, was passiert, wenn wir nicht frei sprechen können. Jeder sollte die Möglichkeit haben, seine Meinung zu äussern, ohne Angst vor Zensur oder Strafe.</p>
      </div>

    </div>
    </div>
  )
};
export default Meinungsfreiheit;

