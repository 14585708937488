import React from "react";
import gsap from "gsap";
import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import { ScrollSmoother } from "gsap/dist/ScrollSmoother"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

const Trompet = () => {
  return (
    <svg
      id="illu"
      data-name="Ebene 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1512 982"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>
          {`
        
      .cls-1 {
        fill: #ebb321;
      }

      .cls-2 {
        stroke: #f29900;
      }

      .cls-2, .cls-3 {
        fill: #004d9d;F
      }

      .cls-2, .cls-4, .cls-5 {
        stroke-miterlimit: 10;
      }

      .cls-2, .cls-5 {
        stroke-width: 22px;
      }

      .cls-4 {
        stroke: #146db5;
        stroke-width: 10px;
      }

      .cls-4, .cls-5 {
        fill: none;
      }

      .cls-6 {
        fill: #f7ba05;
      }

      .cls-7 {
        fill: #146db5;
      }

      .cls-5 {
        stroke: #004d9d;
        stroke-linecap: round;
      }
        `}
        </style>
      </defs>
      <path id="wave_3" className="cls-3" d="M-422.2,762.1s722.4,398.9,2426-144.3c1703.6-543.2,1217,691.9,5292.2,142.7,4075.2-549.2,4569.6,515.2,6724.5,1.5v282.9H-422.2v-282.9Z"/>

      <g id="trompet">
        <path
          className="cls-6"
          d="M1056.83,491h-285.6v73.54h-76.45v-73.54h-285.6c220.75,1.8,274.56,332.4,285.6,557.39v204.25h76.45v-204.25c11.04-224.99,64.85-555.6,285.6-557.39Z"
        />
        <ellipse
          className="cls-2"
          cx="733.01"
          cy="491"
          rx="323.82"
          ry="73.54"
        />
        <path
          className="cls-1"
          d="M916.89,821.05c-10.13-1.71-20.58-2.26-30.78-2.25-29.52.05-58.02-1.3-87.39-3.02,0,0-8.47,46.27-8.74,47.75.53-2.92,96.65.15,105.53,1.08,10.43,1.09,21.26,3.16,29.47,9.68,12.37,9.82,16.95,27.15,14.23,42.22-.39,2.17-1,4.44-2.62,5.94-1.93,1.79-4.79,2.04-7.42,2.18-9.04.47-147.21-4.73-147.68-.6,0,0-2.02,17.81-2.02,17.85.42-3.69,146.8,4.55,157.77,4.98-2.27,21.74-4.87,43.45-7.81,65.12-52.34,1.63-104.52-.88-156.84-.88l-2.14,17.85,156.19,4.86c-13.52,99.21-32.31,218.84-32.31,218.84,0,0,45.84,0,45.84,0,6.02-40.28,12.03-80.55,18.05-120.83,10.39-69.54,28.99-143.17,30.29-213.39.55-30.05-8-62.43-31.63-80.99-11.53-9.05-25.42-13.92-39.97-16.38Z"
        />
        <ellipse
          className="cls-4"
          cx="733.01"
          cy="491"
          rx="244.41"
          ry="51.75"
        />
        <ellipse
          className="cls-4"
          cx="733.01"
          cy="491"
          rx="166.61"
          ry="32.13"
        />
        <ellipse className="cls-4" cx="733.01" cy="491" rx="57.02" ry="14.65" />
        <g id="sound_right">
        <path
          className="cls-5"
          d="M1021.82,415.88s82.34,20.59,82.34,75.48-89.21,75.48-89.21,75.48"
        />
        <path className="cls-5" d="M1104.16,415.88s89.21,75.48,0,144.1" />
        <path className="cls-5" d="M1165.92,436.47s30.42,60.39,0,109.79" />
        </g>
        <g id="sound_left">
        <path
          className="cls-5"
          d="M452.27,415.88s-82.34,20.59-82.34,75.48c0,54.9,68.62,68.62,68.62,68.62"
        />
        <path className="cls-5" d="M363.06,415.88s-68.62,68.62,0,137.24" />
        </g>
      </g>
  <path id="wave_2" className="cls-7" d="M2616.8,942.4s-551.5,398.9-1851.9-144.3C-535.4,255-164.1,1490.1-3274.8,940.9c-3110.7-549.2-3488.2,515.2-5133.1,1.5v282.9s11024.7,0,11024.7,0v-282.9Z"/>
  <path id="wave_1" className="cls-3" d="M-8426.3,892.5s551.5,398.9,1851.9-144.3,929,691.9,4039.8,142.7c3110.7-549.2,3488.2,515.2,5133.1,1.5v282.9h-11024.7v-282.9Z"/>
    </svg>
  );
};

gsap.registerPlugin(useGSAP);

export const Montreuxjazzfestival: React.FC = () => {
  const container = useRef<HTMLDivElement>(null);
  const wave = useRef<gsap.core.Timeline | null>(null);
  const trompet = useRef<gsap.core.Timeline | null>(null);

  useEffect(() => {
    const smoother = ScrollSmoother.create({
      wrapper: "#smooth-wrapper",
      content: "#smooth-content",
      smooth: 1,
      effects: true,
    });
  
    return () => smoother.kill();
  }, []);

  useGSAP(
    () => {
      gsap.set("#sound_right, #sound_left", { opacity: 0 })
      wave.current = gsap.timeline()
      .fromTo("#wave_1", { x: 3012, y: 100 }, { x: 0, y: 100, duration: 10, ease: "power1.inOut", repeat: -1, yoyo: true })
      .fromTo("#wave_2", { x: 0 }, { x: 2212, duration: 15, ease: "power1.inOut", repeat: -1, yoyo: true },0)
      .fromTo("#wave_3", { x: 0, y: 100 }, { x: -3012, y: 100, duration: 12, ease: "power1.inOut", repeat: -1, yoyo: true },0)
      trompet.current = gsap.timeline()
      .fromTo("#trompet", { y: 1000 }, { y: 0, duration: 3, ease: "back" })
      .fromTo("#trompet", { y: 0 }, { y: -50, duration: 2, repeat: -1, yoyo: true, ease: "power1.inOut" })

    },
    { scope: container }
  );

    // Setze das Ziel-Datum auf den 4. Juli 2025, 00:00 Uhr (Schweiz)
    const targetDate = new Date("2025-07-04T00:00:00+02:00").getTime(); 
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  
    function calculateTimeLeft() {
      const now = new Date().getTime();
      const difference = targetDate - now;
  
      if (difference <= 0) {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }
  
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
  
      return () => clearInterval(timer);
    }, []);

  return (
    <div ref={container} id="smooth-wrapper" className="bg-white">
        <div id="smooth-content">
            <div className="h-[50vh] sm:h-screen w-full items-center justify-center relative">
                <h6 className="absolute w-2/3">Montreux Jazz Festival <span className="text-[#F7BA12]">2025</span></h6>
                <div className="absolute bottom-0 right-0 mr-10 mb-10">
                        <Link className="text-[#004D9D] font-['Gluten'] bg-[#F7BA12] py-4 px-6 rounded-full" to="/montreuxjazzfestival/tickets">
                            Buy Tickets
                        </Link>                        
                </div>
                <div className="absolute bottom-0 left-0 ml-10 mb-10">
                    <div className="flex flex-row">
                        <p className="text-[#F7BA12] font-['Gluten'] text-xl sm:text-4xl font-bold">{timeLeft.days}d</p>
                        <p className="text-[#F7BA12] font-['Gluten'] text-xl sm:text-4xl font-bold mx-2">:</p>
                        <p className="text-[#F7BA12] font-['Gluten'] text-xl sm:text-4xl font-bold">{timeLeft.hours}h</p>
                        <p className="text-[#F7BA12] font-['Gluten'] text-xl sm:text-4xl font-bold mx-2">:</p>
                        <p className="text-[#F7BA12] font-['Gluten'] text-xl sm:text-4xl font-bold">{timeLeft.minutes}m</p>
                        <p className="text-[#F7BA12] font-['Gluten'] text-xl sm:text-4xl font-bold mx-2">:</p>
                        <p className="text-[#F7BA12] font-['Gluten'] text-xl sm:text-4xl font-bold">{timeLeft.seconds}s</p>
                    </div>                     
                </div>
                <Trompet />
            </div>
            <div className="sm:h-screen w-full bg-[#004D9D] flex items-center px-10">
                <div className="flex flex-col">
                    <p className="font-['Gluten'] text-6xl sm:text-8xl font-bold sm:mb-8 mt-5 sm:mt-0 text-[#F7BA12]">Acts</p>
                        <div className="flex flex-col sm:flex-row mb-5 sm:mb-20">
                            <div className="flex-col w-full sm:w-1/2 pr-8 mb-5 sm:mb-0">
                                <p className="font-['Gluten'] text-3xl md:text-5xl 2xl:text-6xl font-bold text-white">Mittwoch:</p>
                                <p className="font-['Gluten'] text-3xl md:text-5xl 2xl:text-6xl text-white"><span className="text-[#F7BA12]">MRCY,</span> Judeline, Isabel LaRosa, Myles Smith, Sienna Spiro</p>
                            </div>
                            <div className="flex-col w-full sm:w-1/2">
                                <p className="font-['Gluten'] text-3xl md:text-5xl 2xl:text-6xl font-bold text-white">Freitag:</p>
                                <p className="font-['Gluten'] text-3xl md:text-5xl 2xl:text-6xl text-white"><span className="text-[#F7BA12]">Naïka,</span> Gigi Perez, ANOTR, Luvcat, Alessi Rose</p>
                            </div>
                        </div>
                    <div className="flex flex-col sm:flex-row">
                        <div className="flex-col w-full sm:w-1/2 pr-8 mb-5 sm:mb-0">
                            <p className="font-['Gluten'] text-3xl md:text-5xl 2xl:text-6xl font-bold text-white">Donnerstag:</p>
                            <p className="font-['Gluten'] text-3xl md:text-5xl 2xl:text-6xl text-white"><span className="text-[#F7BA12]">Aremas,</span> Shaboozey, Mel D, Trueno, arøne</p>
                        </div>
                        <div className="flex-col w-full sm:w-1/2 mb-5 sm:mb-0">
                            <p className="font-['Gluten'] text-3xl md:text-5xl 2xl:text-6xl font-bold text-white">Samstag:</p>
                            <p className="font-['Gluten'] text-3xl md:text-5xl 2xl:text-6xl text-white"><span className="text-[#F7BA12]">CA7RIEL & Paco Amoroso,</span> Marcin, Joel Sunny, Addison Rae, Mark Ambor</p>
                        </div>
                    </div>
                </div>
            </div>
            <footer>

            </footer>
        </div>
    </div>
  );
};
export default Montreuxjazzfestival;
