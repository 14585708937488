import React, { useState } from "react"
import ruaha from "../img/projects/tanzania2024/ruaha.jpeg"
import PlainInside from "../img/projects/tanzania2024/plainInside.jpg"
import nyerere from "../img/projects/tanzania2024/nyerere.jpeg"
import ImageLoader from "../components/imageLoader"
import ProjectHeader from "../components/projectHeader"
import ProjectLeft from "../components/projectLeft"
import ProjectRight from "../components/projectRight"
import Tabs from "../components/projectTabs"
import Goat from '../img/projects/swisswildlife/DSC05940.jpg';


const Swisswildlife: React.FC = () => {
  // State zum Verfolgen des aktiven Tabs
  const [activeTab, setActiveTab] = useState<"gallery" | "summary">("gallery")

  // Tab-Daten mit expliziten Typen
  const tabs: { name: "gallery" | "summary"; label: string }[] = [
    { name: "gallery", label: "Gallery" },
    // { name: "summary", label: "Travel Summary" },
  ]
  return (
    <div className="w-full">
      <ProjectHeader
        imageSrc={Goat} // Titelbild
        title="Swiss Wildlife" // Titeltext
        subtitle="2024" // Untertiteltext
      />

      {/* Tabs Komponent */}
      <Tabs
        tabs={tabs} // Hier wird das korrekt typisierte `tabs`-Array übergeben
        activeTab={activeTab}
        onTabChange={(tab: "gallery" | "summary") => setActiveTab(tab)} // Hier korrekt typisiert
      />

      {/* Content */}
      <div className="content">
        {activeTab === "gallery" && (
          <div>
            <ImageLoader folder="swisswildlife" />
          </div>
        )}
        {activeTab === "summary" && (
          <div>
            <ProjectLeft
              imageSrc={PlainInside} // Bild-URL
              altText="Zeigt ein kleines Flugzeug von innen" // Alt-Text für das Bild
              title="Mikumi Nationalpark" // Titeltext
              description="Während zwei Wochen hatte ich das Glück, mit meiner Familie die Tierwelt des Süden Tansanias zu erkunden. Unsere Reise startete im Mikumi Nationalpark. Der Park hat uns gefallen und ich konnte einige schöne Momente mit meiner Kamera festhalten. Jedoch ist der Park für uns zu fest von Menschen berührt. Er wird von einer Hauptstrasse durchquert, welche täglich hunderte von Lastwägen benutzen. Das ist eine Gefahr für die Tiere, besonders in der Nacht. Dann gibt es dort extrem viele Tagestouristen von Sansibar, die an einem Tag möglichst viel sehen wollen und daher durch den ganzen Park brettern, die Natur zerstören und die Tiere aufscheuchen. Im ganzen Park werden ausserdem viele Flächen niedergebrannt und man sieht ständig Baustellen."
            />
            <ProjectRight
              imageSrc={ruaha} // Bild-URL
              altText="Zeigt die Atemberaubende Landschaft vom Ruaha Nationalpark" // Alt-Text für das Bild
              title="Ruaha Nationalpark" // Titeltext
              description="Mit dem Flugzeug ging es zu unserem nächsten Stopp, dem Ruaha Nationalpark. Schon vom Flugzeug aus konnte man sofort erkennen, dass wir nun so richtig in der Wildnis angekommen sind. So weit das Auge reicht, unberührtes Land, keine Trucks, keine Baustellen, nur die Natur. Die Landschaft hier war sehr vielfältig. Meistens bedeckte aber das hohe ausgetrocknete Gras und Baobab bäume die Landschaft. In den nächsten Vier Tagen hatten wir ein extremes Glück. Wir sahen eine dreier Gruppe Geparden, was in diesem Park einmalig ist. Sogar für die Guides ist es eine Überraschung. Für sie ist es die erste Leopardensichtung in diesem Jahr. Nebst dem sahen wir einige Leoparden und wir hatten zusätzlich das Glück, Löffelhunde zu sehen. Ausserdem konnten wir Löwen beim Essen von einem Büffel zuschauen."
            />
            <ProjectLeft
              imageSrc={nyerere} // Bild-URL
              altText="Zeigt die Atemberaubende Landschaft vom Nyerere Nationalpark" // Alt-Text für das Bild
              title="Nyerere Nationalpark" // Titeltext
              description="Unser letztes Ziel war der Nyerere Nationalpark. Die Landschaft war wieder komplett anders als in den Parks zuvor. Hier gibt es nämlich den Fuiji River und Unmengen an Wasser. Daher war die Landschaft viel bewachsener mit Bäumen und Gestrüpp. Dies ist gut für die Natur, jedoch wird das Tierbeobachten dadurch um einiges erschwert. Durch das viele Gestrüpp ist es schwieriger, Tiere zu sehen, und da es viel Wasser gibt, sind die Tiere nicht auf vereinzelte Wasserlöcher angewiesen. Und als ob das nicht schon schwierig genug wäre, zählt der Park noch zu einem der grössten von ganz Afrika. Mit ganzen 50'000 Quadratkilometern ist er grösser als die Schweiz. Trotz weniger Tiersichtungen genossen wir unsere Zeit im Park sehr. Wir verbrachten auch einige Zeit auf dem Boot, wo wir die wunderschöne Vogelwelt beobachten durften."
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Swisswildlife;