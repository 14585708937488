import Dune from '../img/gallery/marokko/dune.jpg'
import Bueffel from '../img/bueffel.jpg'
import Leopard from '../img/projects/tanzania2024/leopard.webp'
import Goat from '../img/projects/swisswildlife/DSC05940.jpg';

interface Post {
    imgSrc: string;
    imgAlt: string;
    title: string;
    text: string;
    src: string;
    home: boolean;
    tags: string;
  }
  
  export const getPostList = (): Post[] => {
    return [
      {
        imgSrc: Leopard,
        imgAlt: 'Leopard sitting on a tree',
        title: 'Im Süden von Tanzania',
        text: 'Zwei Wochen lang tauchten wir in die wilde Natur im Süden Tansanias ein. Eine Reise voller magischer Landschaften und seltener Tierbegegnungen. Von Savannen bis hin zu dichten Flusslandschaften bot uns jeder Park seine eigene, unvergleichliche Schönheit.',
        src: '/2024_tanzania',
        home: true,
        tags: 'Photography'
      },
      {
        imgSrc: Dune,
        imgAlt: 'Dune',
        title: 'Marokko',
        text: 'In zweieinhalb Wochen erlebten wir Marokkos Vielfalt – von Marrakeschs Trubel bis zur Stille der Wüste. Höhepunkt war eine sechstägige Wüstendurchquerung, die uns unvergessliche Momente schenkte.',
        src: '/marokko',
        home: true,
        tags: 'Photography'
      },
      {
        imgSrc: Goat,
        imgAlt: 'Goat',
        title: 'Swiss Wildlife',
        text: '',
        src: '/swisswildlife',
        home: true,
        tags: 'Photography'
      },
      // {
      //   imgSrc: Bueffel,
      //   imgAlt: 'Dune',
      //   title: 'Tanzania',
      //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo',
      //   src: '/2022_tanzania',
      //   home: false,
      //   tags: 'Photography, Videography'
      // },
      // Weitere Post-Objekte hier hinzufügen
    ];
  };
  