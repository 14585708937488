import React, { useState, useEffect } from "react";
import ImageGallery from "./imagegallery" // Importiere deinen ImageGallery-Komponenten

interface ImageLoaderProps {
  folder: string; // Folder-Name als Prop
}

const ImageLoader: React.FC<ImageLoaderProps> = ({ folder }) => {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    // Dynamische API-URL basierend auf dem Ordnernamen
    const apiUrl = `https://maelozelley.ch/api/list_images.php?folder=${folder}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setImages(data); // Setze die Bilder aus der API-Antwort
      })
      .catch((error) => {
        console.error('Error fetching images:', error);
      });
  }, [folder]); // Trigger erneut, wenn der Folder sich ändert

  return (
    <div>
      <ImageGallery images={images} /> {/* Übergib die Liste der Bilder an die Galerie */}
    </div>
  );
};

export default ImageLoader;
