
import React, { useState } from "react"
import Dune from "../img/projects/marokko/dune.jpg";
import city from "../img/projects/marokko/city.webp";
import office from "../img/projects/marokko/officeAchmed.png";
import ImageLoader from "../components/imageLoader"
import ProjectHeader from "../components/projectHeader"
import ProjectLeft from "../components/projectLeft"
import ProjectRight from "../components/projectRight"
import Tabs from "../components/projectTabs"

const Marokko: React.FC = () => {
  // State zum Verfolgen des aktiven Tabs
  const [activeTab, setActiveTab] = useState<"gallery" | "summary">("gallery")

  // Tab-Daten mit expliziten Typen
  const tabs: { name: "gallery" | "summary"; label: string }[] = [
    { name: "gallery", label: "Gallery" },
    { name: "summary", label: "Travel Summary" },
  ]
  return (
    <div className="w-full">
      <ProjectHeader
        imageSrc={Dune} // Titelbild
        title="Marokko" // Titeltext
        subtitle="2023" // Untertiteltext
      />

      {/* Tabs Komponent */}
      <Tabs
        tabs={tabs} // Hier wird das korrekt typisierte `tabs`-Array übergeben
        activeTab={activeTab}
        onTabChange={(tab: "gallery" | "summary") => setActiveTab(tab)} // Hier korrekt typisiert
      />

      {/* Content */}
      <div className="content">
        {activeTab === "gallery" && (
          <div>
            <ImageLoader folder="marokko" />
          </div>
        )}
        {activeTab === "summary" && (
          <div>
            <ProjectLeft
              imageSrc={city} // Bild-URL
              altText="Marrakesch" // Alt-Text für das Bild
              title="Marrakesch" // Titeltext
              description="Während zweieinhalb Wochen reisten Gaston und ich durch das wunderschöne Land Marokko. Unsere Reise startete in Marrakesch, wo wir insgesamt 4 Tage verbrachten. Eine unglaublich belebte Stadt, in der täglich tausende Menschen durch die kleinen engen Gassen strömen. Jeder versucht zu handeln und dir etwas zu verkaufen. Eine Stadt, die niemals stillsteht."
            />
            <ProjectRight
              imageSrc={office} // Bild-URL
              altText="Zagora" // Alt-Text für das Bild
              title="Zagora" // Titeltext
              description="Nach vier intensiven Tagen in Marrakesch führte uns die Reise weiter in Richtung Zagora. Sieben Stunden rumpelten wir im Bus durch atemberaubende Landschaften und Täler, bis wir schliesslich an unserer Unterkunft ankamen. Am nächsten Tag suchten wir unseren Kontakt in der Stadt, den wir telefonisch nicht erreichen konnten. Nach etwas suchen und Herumfragen fanden wir ihn dann. Nach einer kurzen Besprechung in seinem Office (siehe Bild rechts), haben wir uns darauf geeinigt, mit ihm 6 Tage die Wüste zu durchqueren."
            />
            <ProjectLeft
              imageSrc={Dune} // Bild-URL
              altText="Wüste Zagora" // Alt-Text für das Bild
              title="Wüste Zagora" // Titeltext
              description="Die nächsten Tage waren wunderschön. Die Durchquerung der Wüste war wahrscheinlich eine meiner schönsten und lehrreichsten Erfahrungen in meinem Leben. Sechs Tage nur Natur, kein Handy, keine Ablenkung und keine anderen Menschen. Eine wunderschöne Erfahrung, die ich nie vergessen werde. Unterwegs waren wir mit drei Dromedaren, die beladen waren mit Proviant für die Tage und unserem Zelt. Wir starteten im Gebirge, in dem wir auch unsere erste Nacht verbachten. In den nächsten Tagen ging unsere Reise durch das Gebirge, bis wir dann in der grossen Wüste Erg Chegaga ankamen. Sanddünen, so weit das Auge reicht, eine scheinbar nicht endende Wüste. Wir hatten sogar das Glück, einen Wüstenfuchs zu sehen, was wahrscheinlich nun mein Lieblingstier ist. Zu Ende unserer Reise durchquerten wir erneut das Gebirge, bis wir wieder zu einer Strasse kamen, wo wir dann abgeholt wurden, um zurück in die Zivilisation zu fahren."
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Marokko;
