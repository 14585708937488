import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

const FooterComponent: React.FC = () => {
  const container = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline | null>(null);
  const tl1 = useRef<gsap.core.Timeline | null>(null);
  const tl2 = useRef<gsap.core.Timeline | null>(null);

  useGSAP(
    () => {
      tl.current = gsap
        .timeline({ paused: true })
        .fromTo(
          "#instagram-hover",
          { rotate: 0, transformOrigin: "50% 50%" },
          { rotate: 360, duration: 0.5 }
        );
      tl1.current = gsap
        .timeline({ paused: true })
        .fromTo(
          "#youtube-hover",
          { rotate: 0, transformOrigin: "50% 50%" },
          { rotate: 360, duration: 0.5 }
        );
      tl2.current = gsap
        .timeline({ paused: true })
        .fromTo(
          "#linkedin-hover",
          { rotate: 0, transformOrigin: "50% 50%" },
          { rotate: 360, duration: 0.5 }
        );
    },
    { scope: container }
  );

  const Imouseoverin = () => {
    if (tl.current) tl.current.play();
  };
  const Ymouseoverin = () => {
    if (tl1.current) tl1.current.play();
  };
  const Lmouseoverin = () => {
    if (tl2.current) tl2.current.play();
  };
  const Imouseoverout = () => {
    if (tl.current) tl.current.reverse();
  };
  const Ymouseoverout = () => {
    if (tl1.current) tl1.current.reverse();
  };
  const Lmouseoverout = () => {
    if (tl2.current) tl2.current.reverse();
  };

  useEffect(() => {
    // Feather Icons nach dem Rendern ersetzen
    const feather = require("feather-icons");
    feather.replace();
  }, []);
  return (
    <footer
      ref={container}
      className="flex flex-col my-5 sm:flex-row h-16 w-full sm:justify-around justify-start sm:items-center items-start pl-4"
    >
      <div>
        <p className="text-white text-xs sm:text-sm">
          © 2025 Mael Ozelley. All rights reserved
        </p>
      </div>
      <div className="flex">
        <Link
          to="/contact"
          className="text-white underline text-xs sm:text-sm py-2"
        >
          Contact
        </Link>
      </div>
      <div className="flex">
        <a
          onMouseEnter={Imouseoverin}
          onMouseLeave={Imouseoverout}
          target="_blank"
          href="https://www.instagram.com/maelozelley/"
        >
          <i
            id="instagram-hover"
            className="text-white mr-5"
            data-feather="instagram"
          ></i>
        </a>
        <a
          onMouseEnter={Ymouseoverin}
          onMouseLeave={Ymouseoverout}
          target="_blank"
          href="https://youtube.com/@maelozelley?si=B0rOqwxNqYUVJ2_s"
        >
          <i
            id="youtube-hover"
            className="text-white mr-5"
            data-feather="youtube"
          ></i>
        </a>
        <a
          onMouseEnter={Lmouseoverin}
          onMouseLeave={Lmouseoverout}
          target="_blank"
          href="https://linkedin.com/in/mael-ozelley-149415276"
        >
          <i
            id="linkedin-hover"
            className="text-white"
            data-feather="linkedin"
          ></i>
        </a>
      </div>
    </footer>
  );
};

export default FooterComponent;
