import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Tanzania2022 from "../projects/Tanzania2022";
import Marokko from "../projects/Marokko";
import Home from "../home";
import Gallery from "../gallery";
import About from "../about";
import Contact from "../contact";
import Tanzania2024 from "../projects/Tanzania2024";
import Meinungsfreiheit from "../meinungsfreiheit";
import Swisswildlife from "../projects/swisswildlife";
import Montreuxjazzfestival from "../montreuxjazzfestival";
import MinimalLayout from "./minimalLayout";
import Tickets from "../tickets";

export const router = createBrowserRouter([
    {
    path: "/",
    element: <App />,
    children: [
        {path: "", element: <Home />},
        {path: "gallery", element: <Gallery />},
        {path: "about", element: <About />},
        {path: "contact", element: <Contact />},
        {path: "2022_tanzania", element: <Tanzania2022 />},
        {path: "marokko", element: <Marokko />},
        {path: "2024_tanzania", element: <Tanzania2024 />},
        {path: "swisswildlife", element: <Swisswildlife />},
        {path: "meinungsfreiheit", element: <Meinungsfreiheit />},
    ]
},
{
    path: "/montreuxjazzfestival",
    element: <MinimalLayout />, // Kein Header/Footer
    children: [
        { path: "", element: <Montreuxjazzfestival /> },
        { path: "Tickets", element: <Tickets /> }
    ]
  },
]) 