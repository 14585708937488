import React, { useRef, useEffect } from "react";
import BackButton from "./BackButton"; // Falls du einen eigenen BackButton-Komponenten hast

interface ProjectHeaderProprs {
  imageSrc: string; // URL des Bildes
  title: string; // Titeltext
  subtitle: string; // Untertiteltext
}

const ProjectHeader: React.FC<ProjectHeaderProprs> = ({ imageSrc, title, subtitle }) => {
  const container = useRef<HTMLDivElement>(null);

  // Falls du eine besondere Logik zum Scrollen oder Animationen benötigst
  useEffect(() => {
    if (container.current) {
      // Füge hier Logik zum Container hinzu, falls benötigt
    }
  }, []);

  return (
    <div ref={container} className="w-full">
      <div className="h-[calc(100vh-4rem)]">
        {/* Bild */}
        <img
          className="h-[calc(100vh-4rem)] object-cover w-full absolute z-10"
          src={imageSrc}
          alt={title} // Setze den Titel als Alt-Text
        />
        
        {/* Text Container */}
        <div className="absolute flex w-full h-[calc(100vh-4rem)] items-center justify-center z-20">
          <div id="appear_background" className="flex text-center flex-col backdrop-blur-md px-10 py-2 !rounded-2xl">
            <p className="text-white font-bold text-2xl">{title}</p>
            <p className="text-white">{subtitle}</p>
          </div>
        </div>

        {/* Zurück Button */}
        <div className="w-full mt-[80vh] absolute z-20 flex justify-end align-bottom">
          <BackButton />
        </div>
      </div>
    </div>
  );
};

export default ProjectHeader;
