import ruaha from "../img/projects/tanzania2024/ruaha.jpeg";
import PlainInside from "../img/projects/tanzania2024/plainInside.jpg";
import nyerere from "../img/projects/tanzania2024/nyerere.jpeg";
import React, { useState, useEffect } from "react";
import ImageGallery from '../components/imagegallery';
import Goat from '../img/projects/swisswildlife/DSC05940.jpg';

const Tanzania22: React.FC = () => {
  // State to track which tab is active
  const [activeTab, setActiveTab] = useState<"gallery" | "summary">("gallery");
  const [images, setImages] = useState<string[]>([]); // State for image list
  const [loading, setLoading] = useState<boolean>(true); // State for loading state

  // Fetch images from the API when the component mounts
  useEffect(() => {
    // Folder name for images (could be dynamic if needed)
    const folder = "tanzania"; 
    const apiUrl = `https://maelozelley.ch/api/list_images.php?folder=${folder}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setImages(data); // Update the images state with the data from the API
        setLoading(false); // Set loading to false once the data is fetched
      })
      .catch((error) => {
        console.error('Error fetching images:', error);
        setLoading(false);
      });
  }, []);

  // Loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full">
      <div className="h-[calc(100vh-4rem)]">
        <img
          className="h-[calc(100vh-4rem)] object-cover w-full absolute z-10"
          src={Goat} // Example static image, can be updated based on the gallery
          alt=""
        />
        <div className="absolute w-full h-[calc(100vh-4rem)] z-20 justify-center content-center text-center">
          <p className="text-white font-bold text-2xl ">
            Im Süden von Tanzania
          </p>
          <p className="text-white">2024</p>
        </div>
      </div>

      {/* Tabs */}
      <div className="flex justify-center my-4">
        <button
          onClick={() => setActiveTab("gallery")}
          className={`px-4 py-2 font-semibold ${
            activeTab === "gallery"
              ? "border-b-2 border-white text-white"
              : "text-gray-500"
          }`}
        >
          Gallery
        </button>
        <button
          onClick={() => setActiveTab("summary")}
          className={`px-4 py-2 font-semibold ${
            activeTab === "summary"
              ? "border-b-2 border-white text-white"
              : "text-gray-500"
          }`}
        >
          Travel Summary
        </button>
      </div>

      {/* Content */}
      <div className="content">
        {activeTab === "gallery" && (
          <div>
            <ImageGallery images={images} />
          </div>
        )}
        {activeTab === "summary" && (
          <div>
          <div className="h-screen   px-10 flex md:flex-row flex-col justify-center">
            <div className="md:w-1/2 w-full max-h-[66vh] xl:max-h-full flex justify-center xl:ml-20 ml-0 xl:my-20 my-2 xl:mr-10 mr-1">
              <img
                className="object-contain object-center"
                src={PlainInside}
                alt="Zeigt das Portrait vom Fotografen & Videograf Mael Ozelley"
              />
            </div>
            <div className="md:w-1/2 h-max-[33vh] xl:h-max-full w-full flex justify-center align-center flex-col ml-0 md:ml-5 xl:ml-10  xl:my-20 my-0 xl:mr-20 mr-0">
              <h2 className="text-white text-4xl mb-2 font-bold">
                Mikumi Nationalpark
              </h2>
              <p
                id="about_text"
                className="text-white text-justify text-sm xl:text-base font-['Arial'] test"
              >
                Während zwei Wochen hatte ich das Glück, mit meiner Familie
                die Tierwelt des Süden Tansanias zu erkunden. Unsere Reise
                startete im Mikumi Nationalpark. Der Park hat uns gefallen und
                ich konnte einige schöne Momente mit meiner Kamera festhalten.
                Jedoch ist der Park für uns zu fest von Menschen berührt. Er
                wird von einer Hauptstrasse durchquert, welche täglich
                hunderte von Lastwägen benutzen. Das ist eine Gefahr für die
                Tiere, besonders in der Nacht. Dann gibt es dort extrem viele
                Tagestouristen von Sansibar, die an einem Tag möglichst viel
                sehen wollen und daher durch den ganzen Park brettern, die
                Natur zerstören und die Tiere aufscheuchen. Im ganzen Park
                werden ausserdem viele Flächen niedergebrannt und man sieht
                ständig Baustellen.{" "}
              </p>
            </div>
          </div>
          <div className="h-screen px-10 flex md:flex-row flex-col justify-center">
            <div className="md:w-1/2 h-max-[33vh] xl:h-max-full w-full flex justify-center align-center flex-col ml-0 md:ml-5 xl:ml-10  xl:my-20 my-0 xl:mr-20 mr-0">
              <h2 className="text-white text-4xl mb-2 font-bold">
                Ruaha Nationalpark
              </h2>
              <p
                id="about_text"
                className="text-white text-justify text-sm xl:text-base font-['Arial'] test"
              >
                Mit dem Flugzeug ging es zu unserem nächsten Stopp, dem Ruaha
                Nationalpark. Schon vom Flugzeug aus konnte man sofort
                erkennen, dass wir nun so richtig in der Wildnis angekommen
                sind. So weit das Auge reicht, unberührtes Land, keine Trucks,
                keine Baustellen, nur die Natur. Die Landschaft hier war sehr
                vielfältig. Meistens bedeckte aber das hohe ausgetrocknete
                Gras und Baobab bäume die Landschaft. In den nächsten Vier
                Tagen hatten wir ein extremes Glück. Wir sahen eine dreier
                Gruppe Geparden, was in diesem Park einmalig ist. Sogar für
                die Guides ist es eine Überraschung. Für sie ist es die erste
                Leopardensichtung in diesem Jahr. Nebst dem sahen wir einige
                Leoparden und wir hatten zusätzlich das Glück, Löffelhunde zu
                sehen. Ausserdem konnten wir Löwen beim Essen von einem Büffel
                zuschauen.{" "}
              </p>
            </div>
            <div className="md:w-1/2 w-full max-h-[66vh] xl:max-h-full flex justify-center xl:ml-20 ml-0 xl:my-20 my-2 xl:mr-10 mr-1">
              <img
                className="object-contain object-center"
                src={ruaha}
                alt="Zeigt das Portrait vom Fotografen & Videograf Mael Ozelley"
              />
            </div>
          </div>
          <div className="h-screen px-10 flex md:flex-row flex-col justify-center">
            <div className="md:w-1/2 w-full max-h-[66vh] xl:max-h-full flex justify-center xl:ml-20 ml-0 xl:my-20 my-2 xl:mr-10 mr-1">
              <img
                className="object-contain object-center"
                src={nyerere}
                alt="Zeigt das Portrait vom Fotografen & Videograf Mael Ozelley"
              />
            </div>
            <div className="md:w-1/2 h-max-[33vh] xl:h-max-full w-full flex justify-center align-center flex-col ml-0 md:ml-5 xl:ml-10  xl:my-20 my-0 xl:mr-20 mr-0">
              <h2 className="text-white text-4xl mb-2 font-bold">
                Nyerere Nationalpark
              </h2>
              <p
                id="about_text"
                className="text-white text-justify text-sm xl:text-base font-['Arial'] test"
              >
                Unser letztes Ziel war der Nyerere Nationalpark. Die
                Landschaft war wieder komplett anders als in den Parks zuvor.
                Hier gibt es nämlich den Fuiji River und Unmengen an Wasser.
                Daher war die Landschaft viel bewachsener mit Bäumen und
                Gestrüpp. Dies ist gut für die Natur, jedoch wird das
                Tierbeobachten dadurch um einiges erschwert. Durch das viele
                Gestrüpp ist es schwieriger, Tiere zu sehen, und da es viel
                Wasser gibt, sind die Tiere nicht auf vereinzelte Wasserlöcher
                angewiesen. Und als ob das nicht schon schwierig genug wäre,
                zählt der Park noch zu einem der grössten von ganz Afrika. Mit
                ganzen 50'000 Quadratkilometern ist er grösser als die
                Schweiz. Trotz weniger Tiersichtungen genossen wir unsere Zeit
                im Park sehr. Wir verbrachten auch einige Zeit auf dem Boot,
                wo wir die wunderschöne Vogelwelt beobachten durften.{" "}
              </p>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};
export default Tanzania22;
