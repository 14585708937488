import React from 'react';
import gsap from 'gsap';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';

const ASVG = () => {
  return (
  <svg id="gohts_icon" className='h-8 absolute' data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.65 100.48" overflow="visible">
  <defs>
    <style>
      {`
      .cls-1 {
        fill: #fff;
      }
    `}
    </style>
  </defs>
  <path className="cls-1" d="M42.14,0h0C18.87,0,0,18.87,0,42.14v58.34h84.27v-58.34C84.27,18.87,65.41,0,42.14,0ZM26.13,43.85c-5.37,0-9.72-4.35-9.72-9.72s4.35-9.72,9.72-9.72,9.72,4.35,9.72,9.72-4.35,9.72-9.72,9.72ZM58.15,43.85c-5.37,0-9.72-4.35-9.72-9.72s4.35-9.72,9.72-9.72,9.72,4.35,9.72,9.72-4.35,9.72-9.72,9.72Z"/>
  <path id='hand' className="cls-1" d="M121.17,14.42c-.64-.64-1.67-.64-2.31,0l-8.66,8.66-.34-.37,8.58-9.56c.51-.57.46-1.44-.11-1.94l-.15-.13c-.57-.51-1.44-.46-1.94.11l-8.39,9.36-.4-.43,5.61-9.36c.53-.89.44-1.91-.2-2.3-.64-.38-1.59.02-2.12.91l-6.05,10.06.06-5.84c0-.24-.02-.47-.07-.69-.23-1.09-1.01-1.96-1.94-2.08-1.13-.15-2.05.85-2.06,2.24l-.12,10.78-1.88,1.73-.05-.05-32.29,32.29,8.81,8.81,31.65-31.65,7.45-6.92s.01,0,.02-.01l6.4-5.94c.5-.46.53-1.25.06-1.75s-1.25-.53-1.75-.06l-6.13,5.69h0s-.18-.19-.18-.19l-.26-.28,8.74-8.74c.64-.64.64-1.67,0-2.31Z"/>
</svg>
  )
}

gsap.registerPlugin(useGSAP);


export const Contact: React.FC = () => {
  const container = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline | null>(null);
  const hand = useRef<gsap.core.Timeline | null>(null);
  
  useGSAP(
    () => {

      hand.current = gsap.timeline({})
      .fromTo(("#hand"), {transformOrigin: "0 50", rotate: 0}, {rotate: 30, repeat: -1, yoyo: true, duration: 0.5, ease: "none"})

      tl.current = gsap.timeline({paused: true})
      .fromTo(("#contact_button"), {scale: 1}, {scale: 1.1, duration: 0.5}, "+=0")    
      .fromTo(("#gohts_icon"), {x: 0, y: 0, opacity: 0}, {x: 60, y: -10, rotate: 30, opacity: 1}, "-=0.5")
      
      const button = document.getElementById('contact_button');
      
      button?.addEventListener('mouseenter', () => {tl.current?.play()})
      button?.addEventListener('mouseleave', () => {tl.current?.reverse()})
    },
    { scope: container }
  );
  return (
    <div className="text-center" ref={container}>
      <div className="h-[calc(100vh-8rem)] flex justify-center">
        <div className="w-full md:w-1/2 xl:w-1/3 flex flex-col self-center">
          <h1 className="text-4xl md:text-6xl mb-4">Let's Work Togehter</h1>
          <p className="text-white text-center text-xl sm:text-2xl md:text-3xl font-['Arial'] mb-8">I'm always interested in hearing about new projects and opportunities.</p>
          <div className='flex justify-center'>
            <ASVG />
          <button id='contact_button' className='inline-block bg-white text-black px-8 py-3 rounded-full transition-colors'><a href="mailto:info@maelozelley.ch">Contact</a></button>
          </div>
        </div>
      </div>
    </div>
  )
};
export default Contact;

