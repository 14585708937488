import React, { useState } from "react";
import { Calendar, Music, Ticket } from "lucide-react";
import { Link } from "react-router-dom";

interface DayTicket {
  day: string;
  date: string;
  price: number;
  available: boolean;
}

export const Tickets: React.FC = () => {
  const [selectedDay, setSelectedDay] = useState<string | null>(null);

  const tickets: DayTicket[] = [
    { day: "Wednesday", date: "", price: 89, available: true },
    { day: "Thursday", date: "", price: 99, available: true },
    { day: "Friday", date: "", price: 119, available: true },
    { day: "Saturday", date: "", price: 129, available: true },
  ];
  return (
    <div className="xl:h-screen bg-[#004D9D] pb-10 pt-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-12">
          <Music className="h-16 w-16 text-[#F7BA12] mx-auto mb-4" />
          <h1 className="text-6xl 2xl:text-8xl font-['Gluten'] font-bold text-[#F7BA12] mb-2">
            Montreux Jazz Festival 2024
          </h1>
          <p className="text-white font-['Gluten'] text-2xl">
            Wählen Sie Ihren Tag und sichern Sie sich Ihre Tickets für ein
            unvergessliches Erlebnis.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {tickets.map((ticket) => (
            <div
              key={ticket.day}
              className={`bg-white rounded-xl overflow-hidden shadow-lg transition-transform duration-300 hover:transform hover:scale-105 ${
                selectedDay === ticket.day ? "ring-4 ring-[#F7BA12]" : ""
              }`}
            >
              <div className="p-6">
                <Calendar className="h-8 w-8 text-[#004D9D] mb-4" />
                <h2 className="text-xl font-['Gluten'] font-bold text-[#004D9D] mb-2">
                  {ticket.day}
                </h2>
                <p className="text-gray-600 font-['Gluten'] mb-4"></p>
                <div className="text-2xl font-bold text-[#004D9D] mb-4">
                  CHF {ticket.price}
                </div>
                <button
                  onClick={() => setSelectedDay(ticket.day)}
                  className="w-full bg-[#004D9D] font-['Gluten'] text-[#F7BA12] py-3 px-4 rounded-full font-semibold flex items-center justify-center gap-2 hover:bg-opacity-90 transition-colors duration-300"
                >
                  Buy
                </button>
              </div>
            </div>
          ))}
        </div>

        {selectedDay && (
          <div className="mt-8 text-center">
            <button className="bg-[#F7BA12] font-['Gluten'] text-[#004D9D] py-4 px-6 rounded-full font-bold text-lg hover:bg-opacity-90 transition-colors duration-300">
              Proceed to Checkout
            </button>
          </div>
        )}
      </div>
      <div className="flex w-full justify-end mt-10 sm:absolute bottom-0 right-0 mr-10 mb-10">
        <Link
          className="text-[#004D9D] font-['Gluten'] bg-[#F7BA12] py-4 px-6 rounded-full"
          to="/montreuxjazzfestival"
        >
          Back
        </Link>
      </div>
    </div>
  );
};
export default Tickets;
