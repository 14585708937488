// BackButton.tsx
import React from 'react';
import { useEffect } from 'react';
import gsap from 'gsap';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP);


const BackButton: React.FC = () => {
  const container = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline | null>(null);
  
  useEffect(() => {
    // Feather Icons nach dem Rendern ersetzen
    const feather = require('feather-icons');
    feather.replace();
  }, []);

  useGSAP(
    () => {
      tl.current = gsap.timeline({paused: true})
      .fromTo(("#scroll_button"), {scale: 1}, {scale: 1.1, duration: 0.3})

      const button = document.getElementById('scroll_button');
      
      button?.addEventListener('mouseenter', () => {tl.current?.play()})
      button?.addEventListener('mouseleave', () => {tl.current?.reverse()})
    },
    { scope: container }
  );
  return (
    <div ref={container} className='fixed z-20 pointer-events-auto'>
      <button id='scroll_button'
        className="my-5 scroll-smooth bg-white py-2 px-8 mr-6 !rounded-2xl text-black"
      >
        <a href="#top"><i className='text-black' data-feather="chevron-up"></i></a>
      </button>
    </div>
  );
};

export default BackButton;
