import Goat from '../img/Goat_reduce.webp'
import Goat1 from '../img/Goat2_reduce.webp'
import GoatBig from '../img/GoatBig_reduce.webp'
import Rotmilan from '../img/Rotmilan_reduce.webp'
import Leopard from '../img/projects/tanzania2024/leopard.webp'



interface Gallery {
    imgSrc: string;
    imgAlt: string;
    title: string;
  }
  
  export const getGalleryList = (): Gallery[] => {
    return [
      {
        imgSrc: Goat1,
        imgAlt: 'Dune',
        title: 'Marokko',
      },
      {
        imgSrc: Goat,
        imgAlt: 'Dune',
        title: 'Marokko',
      },
      {
        imgSrc: GoatBig,
        imgAlt: 'Dune',
        title: 'Tanzania',
      },
      {
        imgSrc: Rotmilan,
        imgAlt: 'Dune',
        title: 'Tanzania',
    },
      {
        imgSrc: Leopard,
        imgAlt: 'Leopard sitting on a tree',
        title: 'Im Süden von Tanzania',
    },
      // Weitere Post-Objekte hier hinzufügen
    ];
  };
  